import React from 'react'
import { Link } from 'gatsby'

import { PostIndexQuery, PostPageQuery } from '../../../graphql-types'
import styles from './PostArticle.module.css'
import AppButton from '../atoms/AppButton'

type Props = {
  post: NonNullable<PostIndexQuery['allMarkdownRemark']['nodes'][number] | PostPageQuery['markdownRemark']>;
  excerpt?: boolean;
  children?: never
}

const PostArticle: React.FC<Props> = ({ post, excerpt = false }) => {
  const hasExcerpt = excerpt && post.html!.match('<!-- more -->')
  const content = hasExcerpt ? post.excerpt : post.html
  const path = post.fields?.slug || ''

  return (
    <article className={`my-12 ${styles.article}`}>
      <header className="mb-4">
        <time dateTime={post.frontmatter?.date} className="text-gray-600">
          {post.frontmatter?.date}
        </time>
        <h1 className="text-3xl border-t mt-1 pt-1">
          <Link to={path} className="text-black hover:text-black">
            {post.frontmatter?.title}
          </Link>
        </h1>
      </header>
      <div dangerouslySetInnerHTML={{ __html: (content || '') }}></div>
      {hasExcerpt && (
        <div className="mt-6">
          <AppButton to={path} outline>Read More</AppButton>
        </div>
      )}
    </article>
  )
}

export default PostArticle
