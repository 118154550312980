import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

type Props = {
  outline?: boolean;
  circle?: boolean;
  icon?: IconDefinition;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
}

type LinkProps = Props & {
  to: string;
  type?: never;
}

type ButtonProps = Props & {
  to?: never;
  type?: 'button' | 'submit';
}

const AppButton: React.FC<LinkProps | ButtonProps> = (props) => {
  const classes: string[] = [
    'text-center',
    'border',
    'border-blue-500',
    'hover:bg-blue-700',
    'hover:border-blue-700',
    'disabled:opacity-75',
    'disabled:pointer-events-none'
  ]
  if (props.className) {
    classes.push(props.className)
  }
  if (props.outline) {
    classes.push(
      'text-blue-500',
      'hover:text-white',
      'hover:no-underline'
    )
  } else {
    classes.push(
      'bg-blue-500',
      'text-white',
      'hover:border',
      'hover:text-white',
      'hover:no-underline'
    )
  }
  if (props.circle) {
    classes.push('rounded-full', 'inline-block')
  } else {
    classes.push('rounded')
  }

  if (props.to) {
    return (
      <Link to={props.to} className={`inline-block p-2 ${classes.join(' ')}`}>
        {props.icon && (
          <FontAwesomeIcon
            icon={props.icon}
            className={props.children ? 'mr-2' : ''}
          />
        )}
        {props.children}
      </Link>
    )
  } else {
    return (
      <button
        className={`focus:outline-none px-2 py-1 ${classes.join(' ')}`}
        type={props.type || 'button'}
        disabled={props.disabled}
      >
        {props.icon && (
          <FontAwesomeIcon
            icon={props.icon}
            className={props.children ? 'mr-2' : ''}
          />
        )}
        {props.children}
      </button>
    )
  }
}

export default AppButton
